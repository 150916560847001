import React from "react";
import { Container, Row, Col } from "reactstrap";

const FooterLinks = () => {
  const year = new Date().getFullYear();
  return (
    <React.Fragment>
      <div className="footer-alt">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} xs={6} className="lg-start">
              <div className="float-start float-lg-none">
                <p className="copy-rights text-white"> © iOneTech {year}</p>
              </div>
            </Col>
            <Col lg={4} xs={6} className="text-end ">
              <div
                className="float-end float-lg-none"
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  src="assets/images/testimonials/ionetechblanco.png"
                  alt="payment"
                  height="40"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FooterLinks;
