import React, { useState, useEffect } from "react";
import { NavLink } from "reactstrap";

const estilos = {
  navlink: {
    zIndex: 999,
    position: "fixed",
    bottom: "2rem",
    right: "2rem",
  },
  img: {
    height: "3em",
  },
};

const WhatsAppButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <NavLink
    href={`https://api.whatsapp.com/send?phone=56983467674&text=%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20las%20soluciones%20de%20iOneTech.%20%C2%BFPodr%C3%ADas%20brindarme%20m%C3%A1s%20informaci%C3%B3n%3F`}
    target="_blank"
      style={{ ...estilos.navlink, display: isVisible ? "block" : "none" }}
    >
      <img src={"/whatsapp.png"} style={estilos.img} alt="WhatsApp" />
    </NavLink>
  );
};

export default WhatsAppButton;
