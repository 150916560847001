import React, { useState, useCallback, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../common/section-title";
// import ReCAPTCHA from "react-google-recaptcha";
import AOS from "aos";
import "aos/dist/aos.css";

// const CaptchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const Contacto = ({ background }) => {
  const [/*errorMessage*/, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);


  const reCaptchaRef = useRef(null);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  /* const handleRecaptchaChange =*/ useCallback((token) => {
    setReCaptchaToken(token);
    setRecaptchaValidated(!!token);
  }, []);

  const resetRecaptcha = () => {
    try {
      setRecaptchaValidated(false);
      setReCaptchaToken("");
      if (reCaptchaRef.current) reCaptchaRef.current.reset();
    } catch (error) {
      console.error("No se pudo restablecer el captcha");
    }
  };
 // eslint-disable-next-line
  const handleSubmit = (e) => {
    
    e.preventDefault();
     // eslint-disable-next-line  
    if (!recaptchaValidated) {
      setErrorMessage(
        <span style={{ color: "red" }}>Por favor, completa el reCAPTCHA.</span>
      );
      return;
    }

    if (!name || !telefono || !email || !comments) {
      setErrorMessage(
        <span style={{ color: "red" }}>Todos los campos son obligatorios.</span>
      );
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage(
        <span style={{ color: "red" }}>
          El correo electrónico no es válido.
        </span>
      );
      return;
    }

    if (!/^\d+$/.test(telefono)) {
      setErrorMessage(
        <span style={{ color: "red" }}>
          El teléfono solo puede contener números.
        </span>
      );
      return;
    }

    setErrorMessage("");

    // Si pasa la validación, puedes enviar el formulario
    // Aquí se puede agregar la lógica para enviar el formulario a través de una API
    console.log("Formulario enviado");
    // enviar token del reCaptchaToken
    console.log("Formulario enviado con reCaptcha token:", reCaptchaToken);
    showSuccessAlert();
    resetRecaptcha();
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setTelefono("");
    setEmail("");
    setComments("");
  };

  const showSuccessAlert = () => {
    alert("Formulario enviado correctamente");
  };

  return (
    <React.Fragment>
      <section
        className={"section " + (background === "gris" ? "bg-light" : "")}
        id="contact"
      >
        <Container>
          <SectionTitle
            title="CONTACTANOS!"
            desc="¡Nos encantaría escucharte! Ya sea que tengas preguntas, comentarios o sugerencias, estamos aquí para ayudarte. Puedes contactarnos a través de nuestro telefono de contacto, correo electrónico o a través de nuestras redes sociales. ¡Esperamos poder ayudarte en lo que necesites!"
          />

          <Row data-aos="fade-up">
            <Col lg={12}>
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Direccion </span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Av.%20Balmaceda%20%23489%20Oficina%20405C,%20Puente%20Alto"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-muted"
                    >
                      Av. Balmaceda #489 Oficina 405C, Puente Alto, Región
                      Metropolitana, Chile.
                    </a>
                  </span>
                </p>
                <div className="mt-4">
                  <span className="h5">Horario de atencion</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    Lunes a Viernes de 9:00 a 18:00 Hrs.
                  </span>
                  <p className="mt-4">
                    <span className="h5">Telefono</span>
                    <br />
                    <a
                      href="tel:+56947520736"
                      className="text-muted mt-2"
                    >
                      +56 9 4752 0736
                    </a>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Correo electrónico</span>
                    <br />
                    <a
                      href="mailto:mcampos@ione.cl"
                      className="text-muted contact-link mt-2"
                    >
                      contacto@ione.cl
                    </a>
                  </p>
                </div>
              </div>
              <div className="mt-3" data-aos="fade-up">
                <iframe
                  title="Google Maps"
                  width="150%"
                  height="350"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.652106379023!2d-70.57264928480045!3d-33.61621578072872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c518c697f5cb%3A0x8e2c7db63e16cb4!2sAv.%20Balmaceda%20489%2C%20Puente%20Alto%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2sus!4v1624308994917!5m2!1sen!2sus"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </Col>
            <Col lg={8}>
              {/* <div className="custom-form mt-4 pt-4">
                <form name="myForm" onSubmit={handleSubmit}>
                  <p className="error-message" id="error-msg">
                    {errorMessage}
                  </p>
                  <div id="simple-msg"></div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mt-2">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Nombre y Apellido"
                          maxLength={35}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-2">
                        <input
                          name="telefono"
                          id="Telefono"
                          type="text"
                          className="form-control"
                          placeholder="Telefono"
                          maxLength={20}
                          value={telefono}
                          onChange={(e) => setTelefono(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          name="subject"
                          placeholder="E-mail "
                          maxLength={50}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mt-2">
                        <textarea
                          name="comments"
                          id="comments"
                          rows="8"
                          className="form-control"
                          placeholder="Déjanos un mensaje o comentario.."
                          maxLength={936}
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 text-start">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={CaptchaSiteKey}
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                    <div className="col-lg-6 text-end">
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-primary mt-1"
                        value="Enviar"
                      />
                    </div>
                  </div>
                </form>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contacto;
